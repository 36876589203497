/* ------------------------------------------------- *
        refer to everywhere
* -------------------------------------------------- */
/*html,body { overflow-x: hidden !important; }*/

/* ------------------------------------------------- *
        refer to resources.views.partials.nav
* -------------------------------------------------- */
#nav{ height: 92px; border-bottom: 10px solid #09526F;}

#nav .container-fluid{ height: 92px; margin-right: 300px; margin-left: 300px; padding: 0 0;}

#nav .navbar-nav > li > a{
    color: #555555 !important;
    text-decoration: none;
    line-height: 82px !important;
    height: 82px;
    padding-top: 0 !important;
    font-size: 18px;
    font-weight: 400;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-right: 10px;
    padding-left: 0;
    padding-bottom: 0;
}

#nav .navbar-nav > li:last-child > a{ padding-right: 15px !important; }

#nav .navbar-nav > li > a:hover{ color: black !important; }

#nav .navbar-brand > img{
    width: 229px;
    height: 58px;
    line-height: 82px !important;
}

/* ------------------------------------------------- *
        refer to resources.views.partials.footer
* -------------------------------------------------- */
#footer{ background-color: #09526F !important; }

#footer .container{width: 838px !important; padding-left: 0 !important; padding: 0 0;}

#footer .navbar-nav > li > a{
    text-decoration: none;
    line-height: 92px !important;
    height: 92px;
    font-size: 18px;
    font-weight: 400;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-right: 10px;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0 !important;
}

#footer .navbar-nav > li:last-child > a{ padding-right: 15px !important; }

#footer .navbar-nav > li > a:hover{ color: gainsboro !important; background-color: transparent }


/* ------ Homepage not connected ------- */
.slider {
    height: 300px;
    line-height: 290px;
    text-align: center;
}

.bordered {
    border: thin solid #8c8c8c !important;
}

.partners-rep {
    height: 300px;
    line-height: 290px;
    text-align: center;
}
.forms .bootstrap-select, .fieldscomp .bootstrap-select{
    width: 100% !important;
}
.forms input, .forms .bootstrap-select, .forms .g-recaptcha, .forms textarea, .modal-body input, .modal-body textarea{
    margin-bottom: 25px;
}
/*.form-group .bootstrap-select button, .forms .bootstrap-select button, .FirstblockRepertoires .bootstrap-select button, #filters .bootstrap-select button, .block-categories .bootstrap-select button {
    border: 0px;
    border-bottom: 1px solid #292929;
    box-shadow: none !important;
    border-radius: 0px !important;
    background-color: transparent !important;
}*/

#search {
    background-color: transparent;
    border: 1px solid #0c6d93;
    border-radius: 0px;
    color: #0c6d93;
    width: 150px;
}

#search:hover {
    background-color: #0c6d93;
    color: white;
}

#search:hover i {
    color: white;
}


.FirstblockRepertoires .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: auto !important;
}
.iframeblock{
    margin: 10px auto;
    max-width: 730px;
    width: 100%;
}
.editcompany {
    margin-top: 20px;
}
.singlecompany, .addPromo {
    background: #f1f1f1;
    padding: 20px;
}
.singlecompany .btn-link, .btn-details-show {
    margin: 10px 0px;
    display: block;
    max-width: 252px;
    width: 100%;
}
.singlecompany .btn-link:hover, .btn-details-show:hover {
    background: #292929 !important;
}
.fieldscomp .bootstrap-select button,  .blockPromotionPartner .bootstrap-select button {
    border: 0px;
    box-shadow: none !important;
    border-radius: 0px !important;
    background-color: transparent !important;   
}

.forms .bootstrap-select button:hover, #filters .bootstrap-select button:hover,  .fieldscomp .bootstrap-select button:hover, .bootstrap-select button:hover {
    background-color: transparent !important;
}
.forms .dropdown-menu > li > a:hover, .forms .dropdown-menu > li > a:focus, .forms .btn-default:active, .forms .btn-default.active, .forms .open > .btn-default.dropdown-toggle {
    background-color: transparent !important;
    background-image: none !important;
    color: #000 !important;
    border-color: #000 !important;
    box-shadow: none !important;
}
textarea:focus, input:focus, button:focus {
    outline: none !important;
}
a:focus {
    outline: 0px !important; 
    outline-offset: 0px !important; 
}
.top_footer a:focus {
    color: #fff !important;
}
#submitContact {
    margin-top: 30px;
}
.selected_bundle {
    color: #003300;
    background-color: #99cb84;
}

.bundle_block {
    margin: 10px;
    padding: 10px;
    cursor: pointer;
}

.gmap {
    height: 280px;
    width: 100%;
}

.preferences {
    margin-bottom: 50px;
}

.preferences span {
    /*font-size: small;*/
}
img.pull-right {
    padding-left: 20px !important;
}

.blockArticles img {
    width: 100%;
    height: auto;
}
.blockArticles label.thumbnail {
    margin: 0px 0px 5px 0px;
}
#btn-habitations > .row >a {
    margin-bottom: 20px;
}
.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    width: 100% !important;
    min-width: 100% !important;
}
.blockArticles ul.select2-selection__rendered {
    background-color: #fff;
}
.blockArticles .select2-container .select2-search--inline {
    width: 100%;
}
.item {
    min-height: 60vh;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-origin: content-box;
    width: 100%;
    text-align: center;
}
.index-img{
    max-height: 250px;
    overflow-y: auto;
}
.iframeblock {
    height: 90px;
}
.ownerfirstpart .col-md-6, .firstBlockPartner .col-md-6 {
    margin-bottom: 30px;
}
.headeriframe {
    position: absolute;
    top: 40%;
    z-index: 10000;
    width: 100%;
}
.fa-searchproprio {
    background: none;
    border: 0px;
    position: relative;
}
.ownersecondpart #search, .ownersecondpart .fa-searchproprio  {
    top: 0px;
    float: left;
}
.caretspan {
    position: absolute;
    top: 15px;
    right: 20px;
    color: #fff;
    width: 30px;
    height: 20px;
    z-index: 10000;
    text-align: center;
    cursor: pointer;
}
.FirstblockRepertoires #results .singleresult {
    background: #f1f1f1;
    padding: 20px;
}
#results > .col-md-6 {
    margin-bottom: 20px;
} 
.FirstblockRepertoires #results .category {
    text-align: center;
}
.FirstblockRepertoires #results .category input {
    text-transform: uppercase;
    background-color: #292929;
    color: #fff;
    border-radius: 0px;
    font-weight: bold;
    font-size: 14px;
    border: 0px;
    box-shadow: 0px;
}
/***global input styles***/
.topRepertoires .labelrepro input {
    width: auto !important;
}

#containerPromo .form-group {
    margin-bottom: 25px;
}
.form-group #btn-image-box, .form-group #btn-logo-box, .fieldscomp #btn-logo-box, .fieldscomp #btn-image-box {
    width: 100%;
    max-width: 370px;
}
.block-categories.block-images #btn-image-box {
    width: 100%;
}
.colorsdiv {
    margin-top: 20px;
}
.text-paintcolors, .block-categories .bootstrap-select button, .inputnom-tache, .block-taches .block-planif-tache input, input.text-title, .textareaPromo, .input-summary, input.titre-message, textarea.description-message, .inputaddEntreprise, .input-addprovince, .inputContact, .modal-body input, #contact_form input[type=text],#contact_form input[type=password], #contact_form input[type=email], #contact_form textarea, #contact_form .bootstrap-select button, #filters input, #filters textarea, #filters .bootstrap-select button, .fieldscomp input, .fieldscomp textarea, .fieldscomp .bootstrap-select button, .form-group input, .form-group textarea, .form-group .bootstrap-select button, .forms .bootstrap-select button {
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    border-bottom: 1px solid #292929;
    padding: 6px 12px 6px 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    font-size: 14px !important;
    color: #000 !important;
    width: 100%;
    outline: 0px !important;
    font-family: "Raleway", sans-serif !important;
}
.block-categories .bootstrap-select {
    width: 100% !important;
    border-bottom: 0px;
    padding: 0px !important;
}
.col-md-4.decalage-tache {
    margin-bottom: 20px;
}
.block-planif-tache input.btn-confirm-taches {
    border: none !important;
    color: #fff !important;
    height: 45px;
    border-radius: 0;
    background-color: #037096;
    font-size: 18px !important;
    text-transform: uppercase;
    font-family: Lato,sans-serif !important;
    font-weight: bolder;
    margin-top: 25px;
}
.block-categories .bootstrap-select button {
    background-color: #f1f1f1;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    color: #000 !important;
    background-color: #fff !important; 
}
.spanSousMenu  label {
    margin-left: 0px !important;
}
.bootstrap-select.btn-group .dropdown-menu {
    border-radius: 0px !important;
    border-top: 0px !important;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: #fff !important;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 50px white inset !important;
}
textarea {
    min-height: 80px !important;
}
.form-group label {
    margin: 7px;
    float: left;
}
.form-group input[type="radio"], .form-group input[type="checkbox"] {
    display: block;
    margin-top: 10px;
    float: left;
    width: auto !important; 
}
.submitbut {
    width: 100%;
    text-align: center;
    float: left;
}
.partdivs {
    width: 50%;
    float: left;
}
.fullwithform {
    width: 100%;
    float: left;
}
.fullwithform button {
    width: 100%;
}
.bootstrap-select>.dropdown-toggle.bs-placeholder, .bootstrap-select>.dropdown-toggle.bs-placeholder:active, .bootstrap-select>.dropdown-toggle.bs-placeholder:focus, .bootstrap-select>.dropdown-toggle.bs-placeholder:hover {
    border: 0px !important;
    border-bottom: 1px solid #292929 !important; 
    padding: 6px 12px 6px 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    outline: none !important;
}
.blockArticles .form-group label, #filters label {
    font-family: "Raleway", sans-serif !important;
    color: #292929;
    font-size: 14px;
    margin: 5px 9px 0px 9px;
    font-weight: normal;
}

.caretspan {
    display: none;
}
.aboutdiv {
    padding-bottom: 45px !important;
}
a, p, span {
    text-decoration: none !important;
}
@media screen and (max-width: 991px) { 
    .bloglist > div {
        margin-bottom: 40px !important;
    }
    #filters .spanSousMenu {
        padding: 0px !important;
    }
    #contact_form > .col-sm-12 {
        padding: 0px !important;
    }
    iframe {
        margin: 10px auto;
    }
    .topRepertoires .insiderepos {
        margin-bottom: 20px; 
    }
    .inputaddEntreprise, .input-addprovince, .input-summary, .select-habitation, .btn-continuer, .btn-continue, .btn-close, .btn-fermer, .fieldscomp .btn-RightaddPromo {
        max-width: 100% !important;
    }
    .form-group #btn-image-box, .form-group #btn-logo-box, .fieldscomp #btn-logo-box, .fieldscomp #btn-image-box {
        max-width: 100% !important;
    }
} 
@media screen and (max-width: 768px) { 
    .ulFooter {
        margin: 0px;
        padding: 0px;
        border-right: 0px !important;
    }
    li.liFooter {
        padding: 0px 10px;
        float: left;
    }
    .social-network {
        padding-top: 0px !important;
        float: left !important;
    }
    .sopciallinks {
        float: left;
        max-width: 20px;
        width: 100%;
        padding: 0px 15px;
    }
    .caretspan {
        display: block;
    }
    .firstSection iframe {
        padding: 0px !important;
        float: none !important;
        text-align: center;
        display: block;
    }
}
@media screen and (max-width: 620px) { 
    .iframeblock {
        height: 80px;
    }
}
@media screen and (max-width: 580px) { 
    .social-network {
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }
}

button.img_del_btn {
    border: none;
    background: transparent;
}
.fa-dl-img {
    color: #292929 !important;
    margin-left: 85px !important;
}

div[data-target="#lightbox"] {
    cursor:pointer;
}
body.modal-open {
    overflow: inherit;
    padding-right: 0 !important;
}
.align-img {
    display: inline-flex;
    width: 100% !important;
    padding-left: 30px;
}
.max-img {
    max-width:100%;
    max-height: 100%;
}

#ajax-load {
    background-color: #333;
    opacity: 0.2;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10000;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

#ajax-load-gif {
    position: absolute;
    opacity: 1;
    left: 0px;
    top: 0px;
    z-index: 100000;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-image: url('../images/ajax-loader.gif');
    background-position: center;
    background-repeat: no-repeat;
}

img.scale150w {
    max-width: 150px;
    width: 100%;
    height: auto;
    margin-top: 10px;
}

/* video youtube */
.video-wrapper {
	margin: 0 auto;
    max-width: 100%;
    width: 200px;
}

.video-wrapper.home {
    width: 800px;
}

.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
}

.video-container iframe,  
.video-container object,  
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

iframe.pull-right.video {
    margin-left: 50px;
}

@media screen and (max-width: 768px) {
    iframe.pull-right.video {
        display: block;
        float: none!important;
        margin: 0 auto;
    }
} 