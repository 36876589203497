.blockProfil {
    width: 100%;
    background-color: #ffffff;
    height: auto;
}
.modal-content .modal-body {
    margin-left: 0px !important;
}
.modal-content .modal-body label {
    margin-right: 10px;
}
.modal-content .modal-body input {
    color: #000;
    font-size: 13px;   
}

.insideblocks  {
    background-color: #f1f1f1;
    padding: 20px;
}
#results {
    margin-top: 35px;
}
.insideblocks .btn {
    width: 100%;
    padding: 25px 0px;
    text-align: center;
    margin-top: 20px;
    text-transform: uppercase;
    background-color: #292929;
    color: #fff;
    border-radius: 0px;
    font-weight: bold;
}
.insideblocks .btn:hover {
    background-color: #0c6d93;
}
input#buttonProfil1,input#buttonProfil2, input#buttonProfil3,input#buttonProfil4 {
    width: 400Px;
    height: 80px;
    background-color: #292929;
    font-size: 18px;
    font-family: Lato;
    text-transform: uppercase;
    border-radius: 0;
}
input#buttonProfil1:hover {
    background-color: #037096;
}
input#buttonProfil2:hover {
    background-color: #037096;
}
input#buttonProfil3:hover {
    background-color: #037096;
}
input#buttonProfil4:hover {
    background-color: #037096;
}
#element1, #element2, #element3, #element4{
    display: none;
}
input#buttonProfil3 {
    margin-left: 14Px;
}
input#buttonProfil4 {
    margin-left: 14Px;
}

.hello1 {
    background-color: #f1f1f1;
    padding-bottom: 40px;
}

.hello2 {
    background-color: #ff0000;
    width: 300px;
    height: 200px;
    float: right;
    margin-top: -9%;
}
.hello3 {
    background-color: #ff0000;
    width: 300px;
    height: 200px;
    float: right;
}
.hello4 {
    background-color: #ff0000;
    width: 300px;
    height: 200px;
    float: right;
    margin-left: 64%;
    margin-top: -13%;
}
h4 {
    font-family: 'Lato',sans-serif;
}
i.fa.fa-pencil {
    position: absolute;
    margin-left: -2%;
}

.inputProfil {
    width: 95%;
    border: none;
    border-bottom: 1px solid #292929;
    padding-bottom: 7px;
    color:#292929;
    background-color: transparent;
}

.inputProfil::-webkit-input-placeholder { color:#292929; }

.h3Profil {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Lato' , sans-serif;
    font-size: 23px;
    color: #037096;
}
#adresseCourriel, #adresseCourriel2, #adresseCourriel3{
    margin-left:23%;
}
div#adresseCourriel3 {
    margin-left: 24%;
}

.ui-widget-header {
   background-color: #ffffff;
    border: none;
}
input#submitProfil {
    margin-left: 147px;
    margin-top: 30px;
    width: 322px;
    height: 45px;
    background-color: #0c6d93;
    border-radius: 0;
    font-size: 18px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}
#ButtonTheLeft {
    margin-left: -14px;
}
.blockPromotionPartner {
    width: 100%;
    height: auto;
    background-color: #ffffff;
}
#containerPromo >.row>.col-md-6 {
    margin-bottom: 20px;
}
.h2Promotions {
    font-size: 24px;
    font-family: 'Lato', sans-serif;
    color: #0c6d93;
    text-transform: uppercase;
}
div#categoryPromos {
    padding: 10px 20px;
}
p.pModifier {
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Lato',sans-serif;
    float: left;
    width: 100%;
    color: #4e93af;
}
p.pModifier:hover, p.pModifier:focus {
    color: #ffffff;
    cursor: pointer;
}
#pAdd {
    font-size: 16px;
    font-weight: bolder;
    font-family: 'Lato',sans-serif;
    color:  #037096;
}
img.imgAdd {
    display: block;
    margin: 0 auto;
}

p.pAdd {
    font-size: 16px;
    font-family: 'Lato',sans-serif;
    font-weight: bold;
    text-align: center;
    margin-top: 12px;
    text-decoration: none;
    text-transform: uppercase;
    color: #037096;
}
p.pAdd:hover {
    text-decoration: none;
}
#containerPromo {
    padding-bottom: 25px;
}
#filters .bootstrap-select {
    width: 100% !important;
    box-shadow: none !important;
}
#filters span:first-of-type {
    padding-left: 0px;
}
.home-grey-row .col-md-6 div {
    margin-bottom: 10px;
}
.home-grey-row .col-md-6 div input[type='text'] {
    width: 100%;
    text-indent: 10px;
}
.home-grey-row .col-md-6 div button {
    float: right;
}
.home-grey-row .btn.btn-info {
    margin-bottom: 10px;
}
.topfillter {
    margin-bottom: 20px;
}
.h3Succursales {
    text-transform: uppercase;
    color: #fff;
    font-weight: bolder;
    font-family: 'Lato' ,sans-serif;
    margin: 0px;
}
#categorySuccursalesSolo > a {
    background-color: transparent !important;
}
#categorySuccursalesSolo {
    background-color: #0c6d93;
    height: auto;
    text-align: center;
}
.pthumbnailSuccursaleSolo {
    color: #292929;
    font-size: 14px;
    font-family: Lato, sans-serif;
}
#thumbailSuccursaleSolo {
    background-color: #f1f1f1;
    padding: 0;
}

@media screen and (max-width: 768px) {
    .spanAddEntreprise {
        margin-left: 28.2% !important;
        margin-top: -5% !important;
    }
    .firstblockSlider {
        margin-left: 20px !important;
    }
}
@media screen and (max-width: 992px) {
    .flexdiv {
        display: block !important;
    }
    .firstblockSlider {
        margin-left: 20px !important;
    }
    .inputname-prepareoffer {
        margin-left: 200px !important;
    }
    .inputdescription-prepareoffer {
        margin-left: 200px !important;
    }
    .h3succursale-prepareoffer {
        margin-left: 280px !important;
    }
    .h3succursale-descriptionoffer {
        margin-left: 230px !important;
    }
    .h3-dateexpiration {
        margin-left: 230px !important;
    }
    .btn-imgprepareoffer {
        margin-left: 205px !important;
    }
    .btn-submit-prepareoffer {
        margin-left: 210px!important;
    }
}
@media screen and (max-width: 550px) {
    .flexdiv {
        display: block !important;
    }
    .firstblockSlider {
        margin-left: 20px !important;
    }
    .inputname-prepareoffer {
        margin-left: 100px !important;
    }
    .inputdescription-prepareoffer {
        margin-left: 100px !important;
    }
    .h3succursale-prepareoffer {
        margin-left: 150px !important;
    }
    .h3succursale-descriptionoffer {
        margin-left: 130px !important;
    }
    .h3-dateexpiration {
        margin-left: 130px !important;
    }
    .btn-imgprepareoffer {
        margin-left: 105px !important;
    }
    .btn-submit-prepareoffer {
        margin-left: 110px!important;
    }
}


@media screen and (min-width: 769px) and (max-width: 992px) {
    .pthumbnailSuccursaleSolo {
        width: 100%;
    }
    #thumbailIndex {
        margin-left: 0 !important;
    }
    .spanAddEntreprise {
        margin-left: 26% !important;
        margin-top: -4.6% !important;
    }
    .inputname-prepareoffer {
        margin-left: 250px !important;
    }
    .inputdescription-prepareoffer {
        margin-left: 250px !important;
    }
    .h3succursale-prepareoffer {
        margin-left: 350px !important;
    }
    .h3succursale-descriptionoffer {
        margin-left: 300px !important;
    }
    .h3-dateexpiration {
        margin-left: 280px !important;
    }
    .btn-imgprepareoffer {
        margin-left: 325px !important;
    }
    .btn-submit-prepareoffer {
        margin-left: 251px !important;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .thebtn {
        margin-left: 5% !important;
    }
    #uploadPictures {
        margin-left: 0 !important;
        margin-top: 17% !important;
    }
    #btn-confirm-photos {
        margin-right: 68% !important;
        margin-top: 3% !important;
        width: 262px !important;
    }
    .spanAddEntreprise {
        margin-left: 40% !important;
        margin-top: -7.2% !important;
    }
}
#addPromo {
    background-color: #f1f1f1;
    padding: 20px;
    border: none;
}
#addPromo #btn-image-box {
    width: 100%;
}

.btn-RightaddPromo {
    font-size: 16px;
    background-color: #037096;
    padding: 10px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: 'Lato' ,sans-serif;
    font-weight: bold;
    text-decoration: none;
    border: none;
    margin-top: 30px;
}
.btn-RightaddPromo:hover {
    color: #ffffff;
    text-decoration: none;
}
.btn-leftaddPromo:hover {
    color: #ffffff;
    text-decoration: none;
}
.btn-leftaddPromo {
    font-size: 16px;
    background-color: #292929;
    padding: 10px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: 'Lato' ,sans-serif;
    font-weight: bold;
    float: none;
    margin: 20px auto;
    width: 100%;
    max-width: 300px;
    display: block;
    text-decoration: none;
    text-align: center;
}
.btn-leftaddPromo:hover {
    color: #fff;
}
span.span-btn {
    background-color: #037096;
    position: absolute;
    margin-left: -100px;
    padding: 9px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -1.5%;
}
#thumbailIndex {
    float: left;
    margin-top: 0;
    margin-left: 11%;
    border: none;
    background: none;
}
#btn-back-photos {
    float: left;
}
#btn-confirm-photos {
    margin-right: 27%;
}

.thebtn {
    margin-left: 19%;
    float: left;
}

#btn-confirm-photos {
    margin-right: 19%;
    margin-top: -5.1%;
}

#uploadPictures {
    margin-left: 98%;
}

.secondBlockImages {
    width: 100%;
    height:200px;
    background-color: #ffffff;
}
#span-btnPictures {
    position: relative;
    margin-top: -0.5%;
}

.inputaddEntreprise, .input-addprovince, .input-summary, .select-habitation {
    border: none;
    max-width: 370px;
    width: 100% !important;
    font-family: inherit !important;
    padding: 0px !important;
}

input:placeholder, select {
   font-family: inherit !important;
   font-size: 14px !important;
}
input::-webkit-input-placeholder {
    font-family: inherit !important;
    font-size: 14px !important;
    color: #000 !important;
}
input::-moz-placeholder { /* Firefox 19+ */
    font-family: inherit !important;
    font-size: 14px !important;
    color: #000 !important;
}
input:-ms-input-placeholder { /* IE 10+ */
    font-family: inherit !important;
    font-size: 14px !important;
    color: #000 !important;
}
input:-moz-placeholder { /* Firefox 18- */
    font-family: inherit !important;
    font-size: 14px !important;
    color: #000 !important;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    color: #000;
}
.inputaddEntreprise::-webkit-input-placeholder {
    color:#292929;
    font-size: 17px;
    font-family: Lato, sans-serif;
}

a#alignBtn {
    float: left;
}
a#alignBtn2 {
    float: left;
    padding-left: 140px;
    padding-right: 140px;
    margin-top: 2%;
}
.spanAddEntreprise {
    float: right;
    position: absolute;
    margin-left: 33%;
    margin-top: -6%;
    background-color: #292929;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
#h3photos {
    margin-left: 15%;
}

#thumbailRecap {
    background-color: #f1f1f1;
    height: auto;
}
.textareaPromo {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #292929;
}
.thumbnailRepertoire {
    float: right;
    width: 267px;
    height: 257px;
    background-color: #f1f1f1;
    padding: 20px;
}
.h5repertoirespartner {
    color: #292929;
    float: left;
    font-family: Lato, sans-serif;
    margin-right: 10px;
}

.spanrepertoirepartner {
    font-family: Lato, sans-serif;
}
span#cpRepertoire {
    margin-left: 10px;
}
.h4repertoirePartner {
    position: absolute;
    font-family: Lato,sans-serif;
    margin-top: 46px;
    font-size: 20px;
}
h3.demanderRapport {
    text-transform: uppercase;
    font-weight: bolder;
    margin-top: 39px;
    margin-left: 110px;
    font-size: 24px;
    color: #fff;
    font-family: Lato,sans-serif;
}
.topSousMenu {
    width: 100%;
    height: 50px;
}
span.spanSousMenu {
    font-size: 20px;
    color: #292929;
    font-weight: bold;
    font-family: Lato, sans-serif;
    margin-bottom: 25px;
}

#modifierSuccursale {
    border-right: none;
}
i.fa.fa-chevron-down {
    color: #037096;
    font-size: 14px;
}
#imaginary_container{
    margin-top:20%; /* Don't copy this */
}
.stylish-input-group .input-group-addon{
    background: white !important;
}
.stylish-input-group .form-control{
    border-right:0;
    box-shadow:0 0 0;
    border-color:#ccc;
}
.stylish-input-group button{
    border:0;
    background:transparent;
}
input.researchBtn {
    border: none;
    border-bottom: 1px solid #292929;
    float: right;
    width: 350px;
    margin-top: -2.5%;
}

i.fa.fa-search {
    position: relative;
    color: #0c6d93;
    font-size: 22px;
}
#research::-webkit-input-placeholder {
    padding-left: 30px;
    color:#292929;
    font-family: Lato,sans-serif;
}
#thumbnailRapport {
    margin-top: 0;
    border: 1px solid #292929;
    border-bottom: none;
}
.pRapportTop {
    color:#292929;
    font-family: Lato,sans-serif;
    width: 50%;
}

#categoryRapportTop {
    margin-top: 14%;
    height: 120px;
    padding-left: 27%;
    width: 101%;
    margin-left: -0.5%;
}
.h3Rapport {
    color: #037096;
    font-family: Lato, sans-serif;
    font-weight: bold;

}
.thumbnailRapport {
    margin-left: 59%;
    position: absolute;
    z-index: 20;
    margin-top: -30%;
}
input#distanceRapport {
    border: none;
    border-bottom: 1px solid #292929;
    width: 300px;
}
#distanceRapport::-webkit-input-placeholder {
    color:#292929;
    font-family: Lato,sans-serif;
}
i#chevron-top {
    color: #292929;
    margin-left: -6%;
}

select.selectRapport {
    width: 300px;
    border: none;
    border-bottom: 1px solid #292929;
    color: #292929;
    font-family: Lato, sans-serif;
}
.valuesActivity {
    width: 124px;
    display: inline-block;
    background: #292929;
    color: #fff;
}

p.pTopActivite {
    float: right;
    margin-right: 12%;
    padding-top: 3%;
}
i.fa.fa-times {
    margin-left: 6%;
    margin-top: 7%;
}
.valuesActivity:hover {
    background-color: #037096;
}
#chevronSeparator {
    margin-left:50%;
}
p.pAdd:hover{
    color: #0c6d93 !important;
}
p.pAdd:focus{
    color: #0c6d93 !important;
}
.spanrepertoirepartner:hover {
    color: #0c6d93;
}
.service-un {
    float: left;
    width: 144px;
}
.service-deux {
    float: left;
    width: 144px;
}
.service-trois {
    float: left;
    width: 144px;
}
.service-quatre {
    float: left;
    width: 144px;
}
.inputaddEntreprise::-webkit-input-placeholder {
    color:#292929;
    font-size: 17px;
    font-family: Lato, sans-serif;
}
.btn-fermer, .fieldscomp .btn-RightaddPromo {
    max-width: 370px;
    width: 100%;
    height: 45px;
    border-radius: 0;
    background-color: #037096;
    font-size: 18px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}
.fieldscomp .btn-RightaddPromo:hover {
    color: #fff !important;
}
.fieldscomp #alignBtn2 {
    border:  0px !important;
    color: #fff !important;
    font-family: Lato,sans-serif !important;
    font-size: 18px !important;
}
.fieldscomp #alignBtn2, .btn-continue {
    max-width: 370px;
    width: 100%;
    height: 45px;
    background-color: #037096 !important;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 30px;
    border-radius: 0;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    height: 45px !important;
}
input.datepicker-entreprise {
    max-width: 370px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #292929;
}

.service-entrepriseun {
    float: left;
    width: 150px;
}
.service-entreprisedeux {
    float: left;
    width: 150px;
}
.service-entreprisetrois {
    float: left;
    width: 150px;
}
.service-entreprisequatre {
    float: left;
    width: 150px;
}

input.btn-detailscompany {
    width: 200px;
    height: 45px;
    background-color: #292929;
    color: #fff;
    border: none;
    font-size: 16px;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    font-weight: bolder;
}
button.btn-link {
    width: 250px;
    height: 45px;
    background-color: #292929;
    margin-top:30px;
}
button.btn-link:hover {
    background-color: #292929;
}

button.btn-link a {
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}

button.btn-link a:hover, p.pAdd:hover {
    text-decoration: none;
}


input.text-title {
    border: none;
    background: transparent;
    border-bottom: 1px solid #292929;
}

select[multiple], select[size] {
    height: auto;
    background-color: transparent;
    width: 203px;
    margin-left: 163px;
    border: none;
}



input.btn-promotionright {
    height: 45px;
    background-color: #0c6d93;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: bolder;
    text-transform: uppercase;
    float: none;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    display: block;
}

input.inputpopin {
    margin-left: 150px;
    width: 266px;
    border: none;
    border-bottom: 1px solid #292929;
}
.modal-footer {
    border: none;
}
.modal-header {
   border: none;
}
.modal-title {
    margin: 0;
    line-height: 1.6;
    text-align: center;
    font-family: Lato,sans-serif;
    font-size: 21px;
    color: #0c6d93;
    margin-left: 40px;
    text-transform: uppercase;
    font-weight: bold;
}

.btn-profil {
    margin-left: 167px;
    margin-top: 30px;
    width: 262px;
    height: 45px;
    background-color: #0c6d93;
    border-radius: 0;
    font-size: 18px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}
label.mylabel {
    font-size: 18px;
    color: #0c6d93;
    font-weight: bolder;
    text-transform: uppercase;
}
label.label-description {
    font-size: 18px;
    color: #0c6d93;
    font-weight: bolder;
    text-transform: uppercase;
}
.label-create-paintcolors, label.h4Categories, label.mylabel, label.label-description, label.h3succursale-createcompany, .summary .form-group label, .fieldscomp .h3succursalessolo, label.h4-summary {
    margin: 0px;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif !important;
    width: 100%;
    color: #0c6d93 !important;
    font-size: 14px !important;
    padding-bottom: 5px;
}

.go-left {
    margin-left: 0;
}
.logo-select {
    width: 336px;
    margin-top: 19px;
}
select#idservicecategories {
    width: 300px;
    border: none;
    border-bottom: 1px solid #292929;
}
.block-repertoires {
    margin-top: 0;
}
select#distance {
    border: none;
}

select#idcompany {
    border: none;
    margin-right: 50px;
}
.btn-research {
    border: none;
    background: transparent;
}
.map-repertoires {
    margin-left: -15px;
    margin-top: 50px;
}
.btn-properties {
    float: right;
    margin-right: 27px;
    border: none;
    background-color: #0c6d93;
    height: 45px;
    color: #fff;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-size: 16px;
    font-weight: bolder;
}
.span-property {
    font-family: Lato,sans-serif;
    font-size: 14px;
    font-weight: bolder;
    color: #0c6d93;
}
.h3succursale-prepareoffer {
    margin-left: 550Px;
    color: #0c6d93;
    font-size: 20px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}

.inputname-prepareoffer {
    float: left;
    margin-left: 450px;
    border: none;
    border-bottom: 1px solid #292929;
    width: 237px;
}
.h3succursale-descriptionoffer {
    margin-left: 510px;
    color: #0c6d93;
    font-size: 20px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}

.inputdescription-prepareoffer {
    float: left;
    margin-left: 450px;
    border: none;
    border-bottom: 1px solid #292929;
    width: 237px;
    height: 50px;
}
.h3succursale-imageoffer {
    margin-left: 534px;
    color: #0c6d93;
    font-size: 20px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}
.form-images-prepareoffer {
    width: 236px;
    margin-left: 452px;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #292929;
    border-radius: 0;
}
.btn-submit-prepareoffer {
    width: 236px;
    height: 45px;
    background-color: #0c6d93;
    border: none;
    color: #fff;
    font-size: 18px;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    margin-left: 453px;
    margin-top: 30px;
}
.block-prepareoffer {
    height: 650px;
}
.btn-deleteleft, .btn-deletecompany, .decalage-tache form input[type=submit] {
    background-color: #0c6d93;
    color: #fff;
    width: auto !important;
    font-size: 12px !important;
    margin: 0px !important;
    height: auto !important;
    padding: 5px 10px !important;
    border:none;
}
.btn-company {
    margin-left: -30px;
    width: 250px;
    border-radius: 0;
    background-color: #0c6d93;
    font-size: 18px;
    font-weight: bolder;
    text-transform: uppercase;
}
.btn-company:hover {
    margin-left: -30px;
    width: 250px;
    border-radius: 0;
    background-color: #0c6d93;
    font-size: 18px;
    font-weight: bolder;
    text-transform: uppercase;
}
a.btn-link {
    background: #292929;
    padding: 14px;
    padding-left: 13px;
    padding-right: 13px;
    color: #fff;
    font-size: 14px;
    font-weight: bolder;
    text-transform: uppercase;
}

a.btn-link:hover{
    background: #292929;
    color: #fff !important;
    text-decoration: none;
}

a.btn-link:active {
    background: #292929;
}
.form-succursales-repertoires {
    float: right;
    margin-top: -60px;
}

.description-properties {
    margin: 25px 0px;
    border: 1px solid #0c6d93;
    position: relative;
    padding: 20px;
}
.description-properties > div {
    margin-bottom: 10px;
}
.description-property.field-checkbox {
    position: absolute;
    top: 15%;
    right: 30px;
    text-align: right;
}
.aa {
    width: 150px;
    height: 22px;
    box-shadow: none;
    margin-top: -29px;
    margin-left: 188px;
    border-radius: 0;
}
.select-repdeux {
    width: 150px;
    margin-top: -54px;
    margin-left: 377px;
    height: 22px;
    border-radius: 0;
    box-shadow: none;
}

.select-reptrois {
    width: 150px;
    height: 22px;
    margin-top: -21px;
    margin-left: 565px;
    border-radius: 0;
    box-shadow: none;
}

.form-succursales {
    float: right;
}
.btn-research {
    margin-left: 1100px;
    margin-top: -31px;
}
.singlecompany .btn-link {
    padding: 4px 10px !important;
    width: auto !important;
}
.singlecompany .btn-details-show {
    text-transform: capitalize !important;
    background-color: transparent !important;
    color: #287d9e !important;
    padding: 0px !important;
    width: auto !important;
}
.btn-details-show {
    background-color: #292929;
    color: #fff;
    border: none;
    font-size: 14px;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    padding: 14px 0px;
    font-weight: bold;
}

.h4-profil {
    text-align: center;
    color: #0c6d93;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    font-weight: bolder;
}
.add-padding-partnerprofile h4 {
    text-align: left;
}
.middlediv {
    margin: 0 auto;
    float: none;
}
button.btn-profile-form {
    margin-top: -20px;
    border: none;
    background: transparent;
}
.add-padding-partnerprofile {
    padding-bottom: 100px;
}

.btn-left-profile {
    width: 100%;
    float: left;
    background: #0c6d93;
    border-radius: 0;
    color: #fff;
    font-size: 16px;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    border: none;
    padding: 5px 10px;
    margin-bottom: 10px !important;
}
.btn-left-profile:hover {
    background: #0c6d93;
    color: #ffffff;
}
.btn-right-profile:hover {
    background: #0c6d93;
    color: #ffffff;
}
.btn-right-profile {
    float: left;
    background: #0c6d93;
    border-radius: 0;
    color: #fff;
    font-size: 16px;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    border: none;
    padding: 5px 10px;
    margin: 0px !important;
    width: 100%;
}
.decalage-left {
    margin-left: 144px;
}
.btn-profile-form {
    background: transparent !important;
    box-shadow: none !important;
    float: right;
    margin-top: -69px !important;
    width: 0 !important;
}
.color-pencil {
    color: #292929;
}
.pencil-bundle {
    position: absolute;
    margin-left: 185px !important;
    margin-top: -30px !important;
    font-size: 18px;
}

.btn-profile-bundle {
    background: transparent !important;
    box-shadow: none !important;
    margin-left: 264px !important;
    margin-top: -204px !important;
    border: none !important;
}

div#modal_bundle {
    padding: 0 !important;
    margin-top: -40px !important;
}

.form-succursales {
    float: left;
}

.h1-preferences {
    text-align: center;
    color: #0c6d93;
    font-family: Lato, sans-serif;
    text-transform: uppercase;
    font-weight: bolder;
    margin-top: 60px;
    margin-bottom: 30px;
}

.block-preferences {
    width: 100%;
    height: auto;
    background: #fff;
}
.save-preferences {
    background: #0c6d93;
    border: none;
    color: #fff;
    font-size: 18px;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    margin: 0 auto;
    display: inherit;
    padding: 10px 50px;
    margin-bottom: 30px;
}
.check {
    opacity:0.5;
    color:#996;
}
.caption {
    color: #292929;
    text-align: center;
    font-family: Lato,sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}
.caption a {
    text-decoration: none;
}
.flexdiv {
    /*display: flex;
    flex-wrap: wrap;*/
}
.block-preferences .flexdiv .btn-primary:hover {
    background: none;
}
.btn-delete-habitations {
    width: 547px !important;
    background-color: #0c6d93 !important;
    height: 38px !important;
    margin-top: 0px;
    border: none;
}
.editcompany .fieldscomp {
    max-width: 360px;
    width: 100%;
    margin-bottom: 20px;
}
.editcompany .fieldscomp input, .editcompany .fieldscomp textarea, .editcompany .fieldscomp .btn-RightaddPromo {
    width: 100%;
}
.editcompany .fieldscomp .btn-RightaddPromo {
    text-align: center;
    display: block;
    margin-bottom: 20px;
}
.block-planif-tache>div {
    display: block;
    margin-bottom: 20px;   
}
a:hover {
    color: #216a94 !important;
}
.btn-primary:hover {
    background-color: #037096; 
    color: #fff !important;
}

.add-top{
    margin-top: 100px !important;
}

.firstBlockPartner .col-md-6 {
    margin-bottom: 20px;
}
.middle-btn {
    width: 100%;
}
.subtitle-sucursalessolo {
    display: inline-block;
    color: #0c6d93;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}
input.btn-deletecompany {
    width: 100% !important;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: bolder;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
}

.h3-dateexpiration {
    margin-left: 482px;
    margin-top: 14px;
}

label.h3succursale-createcompany {
    color: #0c6d93;
    font-size: 20px !important;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder ;
    margin-top: 30px;
}
.btn-imgprepareoffer {
    margin-left: 527px;
}