html, body:not(.home) {
    height: 100%;
    background-color: #f1f1f1;
}

img#imgbg {
    top: 0;
    width: 100%;
}
.paragraphabout {
    font-size: 14px;
    color:#000000;
    width: 42%;
    font-family: 'Lato', sans-serif;
}
.firstSection {
    background-color: #f1f1f1;
    max-width: 100%;
    height: auto;
}
.firstSection iframe {
    padding: 0px 0px 10px 10px;
}
section.content_section  {
    padding-top: 20px;
}
section.content_section.container.menu-mobile {
    width: 100%;
}

.h2About {
    font-family: Lato,sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    color:#0c6d93;
}
p.lead {
    font-family: Lato, sans-serif;
    font-size: 14px;
    width: 50%;
    color: #000000;
}

.secondSection {
    background-color: #ffffff;
    max-width: 100%;
    padding: 20px 0px;
}
.secondSection .col-md-3 {
    margin-bottom: 20px;
}
a.thumbnail {
    margin: 0px;
    border: none;
    background-color: #fff;
    box-shadow: none;
    text-decoration: none;
}

.pabout {
    font-size: 14px;
    color:#000000;
    width: 52%;
    font-family: 'Lato', sans-serif;
}
.home section.content_section.container.menu-mobile {
    padding: 0;
}

section.content_section.container.menu-mobile {
    padding: 50px 0 50px 0;
}

.blockconditions {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 30px 60px;
}
.pconditions {
    font-size: 14px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 1.6em;
}

.h2Conditions {
    font-family: Lato,sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    color: #0c6d93;
}
.h2Contact {
    font-family: Lato,sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    color: #0c6d93;
    margin: 40px 0;
    text-align: center;
}

#moveLeft {
    padding-left: 120px;
}

#submitContact {
    width: 100%;
    color: #ffffff;
    font-size: 20px;
    font-weight: bolder;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    border-radius: 0px !important;
}
.contactBlock {
    width: 100%;
    height: auto;
    background-color: #ffffff;
}
.pCourriel {
    text-align: center;
    color: #292929;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    width: 100%;
    float: left;
    margin: 25px 0px;
}
@media screen and (max-width: 1200px) {
    #moveLeftText {
        padding-left: 0;
    }
    #moveLeft {
        padding-left: 0;
    }
}
.inputContact {
    border: none;
    border: 1px solid #ddd!important;
    padding: 5px!important;
    width: 100%;
}
#nom::-webkit-input-placeholder { color:#292929; font-size: 20px; }
#prenom::-webkit-input-placeholder { color:#292929; font-size: 20px;}
#companie::-webkit-input-placeholder { color:#292929; font-size: 20px;}
#sujet::-webkit-input-placeholder { color:#292929; font-size: 20px;}
#message::-webkit-input-placeholder { color:#292929; font-size: 20px;}
@media screen and (max-width: 720px) {
    .footerwidget {
        float: left;
    }
}
#containerPromo h2 {
    margin-top: 40px;
    margin-bottom: 20px;
}

#lightbox .modal-content {
    display: inline-block;
    text-align: center;
}
#lightbox .close {
    opacity: 1;
    color: rgb(255, 255, 255);
    background-color: rgb(25, 25, 25);
    padding: 10px 10px;
    border-radius: 0px;
    border: 2px solid rgb(255, 255, 255);
    position: absolute;
    top: 201px;
    right: -55px;
    z-index: 1032;
}