html, body {height: 100%;}
nav#bgcolor {
    background: #292929;
    z-index: 10000;
}

.topbarPartner .contient {
    margin-bottom: 0;
    margin-top: 0px;
    padding: 40px 0;
}

.navbar-default .navbar-collapse {
    padding: 0px;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #333;
    background-color: transparent;
}
img.img-responsive.img-logo {
    z-index: 1;
}
.navbar-default .navbar-nav li  a {
    font-family: 'Lato', sans-serif;
    font-weight: lighter;
    font-size: 13px;
}
.navbar-default .navbar-nav > li > a {
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 15px;
    padding: 15px 7px;
}

.navbar-default .navbar-nav .btn {
    border: 0px;
    box-shadow: none;
    display: inline-block;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    line-height: 10px;
    margin: 0px 2px;
    width: 110px;
}

.navbar-default .navbar-nav .btn.bigger {
    width: 130px;
}

.navbar-default .navbar-nav .btn.transparent {
    background-color: transparent;
    border: 1px solid #ffffff;
}

.navbar-default .navbar-nav .btn:hover {
    background-color: #ffffff;
    color: #0c6d93!important;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:hover {
    color: #fff !important;
}

a.size {
    font-size: 12px;
    font-family: 'Lato', sans-serif;
}

a.size:hover {
    color:#ffffff;
}

a.underline {
    color: #0c6d93;
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: underline!important;
    text-transform: uppercase;
}

a.underline:hover {
    color: #292929!important;
}

a.btn-social.btn-outline {
    padding: 4px;
}
nav.navbar.navbar-default.navbar-static-top {
    background: #0c6d93;
}
.navbar-right {
    margin-right: 30px;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #fff !important;
}
.sous-list {
    background-color: #000000;
}
.toppx .dropdown-menu > li > a:hover, .toppx .dropdown-menu > li > a:focus {
    text-decoration: none;
    color: #ffffff;
    background-color: #037096;
    background-image: none;
}
.link {
    color: #ffffff;
}
.toppx .dropdown-menu > li > a {
    color:#ffffff;
}
.toppx .navbar-default .navbar-nav > .open > a, .toppx .navbar-default .navbar-nav > .open > a:hover, .toppx .navbar-default .navbar-nav > .open > a:focus {
    background-color: #292929;
    color: #ffffff;
    background-image: none;
}
.toppx .dropdown-menu {
    background-color: #292929;
}
.toppx .navbar-nav > li > .dropdown-menu {
    margin-top: -1px;
}
.toppx .navbar-nav .caret {
   display: none;
}

nav#homeColor {
    background: #292929;
    z-index: 10000;
}
#secondHomeColor {
    background-color: #037096;
}
ul#colorLink {
    margin-right: 5px;
}

a#colorLink4:hover {
    color: #ffffff;
}
a#colorLink3:hover {
    color: #ffffff;
}
a#colorLink2:hover {
    color: #ffffff;
}
a#colorLink1:hover {
    color: #ffffff;
}
@media screen and (max-width: 995px) {
    .topbarPartner {
        position: relative !important;
    }
}
@media screen and (max-width: 995px) {
    body {
        background-color: #ff0000;
    }
    .pHomer {
        margin-top: 51% !important;
        width: 100% !important;
    }
    img#imgSecondPartHomePartner {
        margin-right: 14%;
    }
    #powner {
        margin-top: 0 !important;
    }
    .span8 {
        margin-left: 0 !important;
        margin-top: 5% !important;
    }
}
@media screen and (max-width: 630px) {
    img#imgSecondPartHomePartner {
        margin-right: 2%;
    }
}
div#slider {
    float: right;
    margin-top: -44%;
}
#containerHomePartner {
    padding-left: 15px;
    padding-right: 15px;
}
#myCarousel {
    z-index: 1000;
    position: relative;
}
div#myCarousel {
    display: none;
    top: 0;
    width: 100%;
}
nav#secondHomeColor {
    z-index: 10000;
}
img.imgSize {
    width: 100%;
}
.topbar {
    width: 100%;
    height: 198px;
    padding-top: 2%;
    background-image: url(../logo/bgimg.png);
}
.navbar-default {
    border: none;
}
span.glyphicon.glyphicon-chevron-right {
    display: none;
}
span.glyphicon.glyphicon-chevron-left {
    display: none;
}
.carousel-indicators .active {
    background-color: #0c6d93;
    border: none;
}
.carousel-indicators li {
    background-color: #ffffff; \9;
}
.firstblockSlider {
    position: absolute;
    margin-top: 11%;
    margin-left: 20%;
    width: 100%;
    max-width: 700px;
    z-index: 10000;
    height: 65px;
    background-color: #0c6d93;
}
.secondblockSlider {
    position: absolute;
    margin-top: 15%;
    margin-left: 20%;
    width: 100%;
    max-width: 700px;
    z-index: 10000;
    height: 65px;
    background-color: #ffffff;
}
.toppx {
    margin-top: 1%;
}
.firstBlockPartner {
    position: relative;
    height: auto;
    background-color: #ffffff;
    width: 100%;
}
.home-white-row {
    position: relative;
    height: auto;
    background-color: #ffffff;
    width: 100%;
}
.home-grey-row {
    position: relative;
    height: auto;
    background-color: #f1f1f1;
    width: 100%;
}
.square {
    padding: 20px;
}
.category {
    background-color: #292929;
    padding: 20px;
    width: 100%;
    position: relative;
    margin-top: -10px;
}

.h3Partner {
     font-size: 19px;
     color: #ffffff;
     font-weight: bolder;
     font-family: Lato,sans-serif;
    text-transform: uppercase;
    float: left;
    width: 100%;
    margin: 10px 0px;
 }
.h4Partner {
    color: #ffffff;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    font-weight: lighter;
    font-size: 16px;
    float: left;
    width: 100%;
    margin: 0px 0px 10px 0px;
}
.SecondBlockPartner, .ownersecondpart {
    width: 100%;
    height: auto;
    background-color: #f1f1f1;
    padding-bottom: 50px;
}
.h2Partner {
    color:#0c6d93;
    font-family: Lato, sans-serif;
    text-transform: uppercase;
}
.pHomer {
    font-size: 14px;
    font-family: Lato, sans-serif;
    color: #000000;
}
.iframecenter {
    margin: 0 auto;
    max-width: 340px;
    width: 100%;
}
img#imgSecondPartHomePartner {
    padding-bottom: 30px;
}

.h5Partner {
    color: #0c6d93;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    font-weight: lighter;
}
#test {
    position: relative;
    z-index: 100000;
}
.contient {
    z-index: 10000;
    margin: 20px 0;
}
.span8 {
    margin-left: 54%;
    position: relative;
    margin-top: -32%;

}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
    color: #fff !important;
}
.nav {
    list-style: none none;
    margin: 0;
    padding: 0;
    line-height: 1;
}
.nav a {
    display: block;
    padding:.5em;
    color: #ffffff;
    background: transparent;
    text-decoration: none;
}
.nav a:focus,
.nav a:hover {
    color: black;
    background: #ffc;
    text-decoration: underline;
}
.nav-item {
    float: left; /* Pour que les liens s’affichent horizontalement */
    position: relative; /* Crée un contexte de positionnement pour les sous-listes */
}
.sub-nav {
    color: #0c6d93;
    display: none;
    position: absolute;
    white-space: nowrap;
    background: #f1f1f1;
    min-width: 100%;
    left: 0;
    top: 45px;
    overflow-y: scroll;
    max-height: 340px;
    overflow: overlay;
    padding: 15px;
}

.sub-nav li:before {
    display: inline-block;
    content: "-";
    width: 1em;
}
.sub-nav li a {
    color: #0c6d93;
    display: inline-block;
    font-size: 14px!important;
    font-weight: bold!important;
    padding: 5px 15px 5px 0;
}
.sub-nav li a:hover {
    color: #292929!important;
}
.sub-nav li.active {
    background-color: #0c6d93;
}
.nav-item:hover .sub-nav {
    display: block; /* Affiche cette sous-liste au survol de son conteneur */
}
a.sub-menu {
    color: #fff;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-size: 13px;
}
/*a.sub-menu.put-rightside {
    margin-left: 327px;
}
ul.nav.sub-nav.nav-right {
    margin-left: 450px;
}*/

a.btn.btn-info {
    width: 270px;
    height: 40px;
    background-color: #0c6d93;
    border-radius: 0;
    font-size: 15px;
    font-weight: bolder;
    text-transform: uppercase;
}
a.btn.btn-info.scroll:hover {
    color: white!important;
}
.thumbnail {
    border: none !important;
    background-color: transparent;
    box-shadow: none !important;
}

.modal-content {
    margin-top: 200px;
}

button.btn.btn-info.btn-lg {
    width: 400Px;
    height: 80px;
    background-color: #292929;
    font-size: 18px;
    font-family: Lato;
    text-transform: uppercase;
    border-radius: 0;
    margin-left: -15px;
}
.blockProfil, .blockProfil .col-md-6  {
    padding-bottom: 20px;
}

.blockProfilowner h4, .add-padding-partnerprofile h4 {
    color:#037096;
    margin-top: 20px;
}

.right-menu {
    padding: 0;
}
.right-menu .navbar {
    margin-bottom: 0;
    border-radius: 0;
    min-height: auto !important;
}
.right-menu #homeColor.navbar {
    min-height: auto !important;
}

.contient .logo {
    background-color: #fff;
    padding: 0;
}
.topbarPartner {
    z-index: 10000;
    width: 100%;
    position: relative;
}
header {
    background-color: #292929;
    position: relative;
    min-height: 140px;
}
.contient .logo .navbar-brand {
    padding: 3px 0 !important;
    height: auto !important;
}
.contient .navbar-toggle {
    border-radius: 0 !important;
}
.navbar-default .navbar-toggle {
    border: 0px;
    margin: 15px 0 30px 0;
    padding: 0px;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
}
/*.sub-nav-owner {
    margin-left: 318px !important;
}*/

a.h2-link {
    color: #0c6d93 !important;
    text-decoration: none !important;
}

@media (max-width: 768px) {
    header {
        min-height: 100px;
    }
    .topbarPartner .contient {
        padding: 20px 0!important;
    }
    .contient .logo .navbar-brand {
        margin-top: 0px!important;
    }
    .navbar {
        text-align: left!important;
    }
    .sub-nav {
        position: relative !important;
        top: 0px !important;
    }
    .nav > li {
        width: 100%;
        float: left;
    }
    .nav-item:hover .sub-nav {
        display: none;
    }

    .contient .logo .navbar-brand {
        left: 0px;
        position: absolute;
        z-index: 99999;
    }
}

@media (max-width:992px) {
    .navbar-default .navbar-nav .btn {
        margin: 5px 0;
    }
}

@media (min-width:768px) and (max-width:992px) {
    .navbar-default .navbar-nav > li > a {
        font-size: 13px;
        padding: 15px 5px;
    }
}

@media (max-width:1199px) {
    .topbarPartner .contient {
        padding: 15px 0 30px 0;
    }
    .contient .logo .navbar-brand {
        margin-top: 30px;
    }
    .navbar {
        text-align: right;
    }
    .navbar-nav > li {
        display: inline-block;
        float: none;
    }
    .navbar-nav li.button{
        text-align: right!important;
        width: 100%;
    }
}

@media (min-width:1200px) {
    .navbar-default .navbar-nav li.button {
        padding-left: 20px;
    }
}

