.blockConnection {
    width: 100%;
    height: auto;
    background-color: #ffffff;
}
.blockConnection h3 {
    font-size: 21px;
}
h1, h3 {
    font-family: Lato, sans-serif;
}
.inputConnexion {
    border: none;
    border-bottom: 1px solid #292929;
}
.inputConnexion::-webkit-input-placeholder { color:#292929;
    font-size: 18px;
    padding-top: -10px;
}
a.aNonConnect {
    float: right;
    color: #0c6d93;
    font-family: Lato, sans-serif;
    text-decoration: none;
    width: 100%;
    text-align: right;
    padding: 5px 0px;
    display: block;
}
p.pMember {
    float: left;
    font-size: 16px;
    color: #292929;
    font-family: Lato,sans-serif;
    float: left;
    padding: 10px 0px; 
    margin: 0px;
}
p.pInscription {
    font-family: Lato,sans-serif;
    font-size: 16px;
    color: #0c6d93;
    float: left;
    padding: 10px 0px; 
}
button#btnPartner {
    float: left;
    width: 100%;
    border-color: #f1f1f1;
    border-radius: 0px;
    color: #292929;
    font-weight: lighter;
    font-size: 16px;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
}
button#btnPartner:hover {
    background-color: #ffffff;
    color:#0c6d93;
}

button#btnOwner {
    width: 100%;
    border-color: #f1f1f1;
    border-radius: 0px;
    color: #292929;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: lighter;
    font-size: 16px;
}
button#btnOwner:hover {
    background-color: #ffffff;
    color:#0c6d93;
}
#pOu {
    text-align: center;
    width: 100%;
}
.pInscriptionProprio {
    text-align: center;
    margin-top: 20px;
}
.aLien {
    color: #037096;
    font-weight: bolder;
    text-decoration: none !important;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  /*  button#btnOwner {
        background-color: #ffffff;
        color: #0c6d93;
        width: 175px;
    }

    button#btnPartner {
        background-color: #ffffff;
        color: #0c6d93;
        width: 175px;
    }

    select#selectNonConnect {
        width: 100% !important;
    }

    p.pRegister {
        width: 85% !important;
        margin-left: 8% !important;
    }

    span.select2-selection.select2-selection--multiple {
        width: 944px !important;
    }
    
    .h4Test {
        margin-left: -23% !important;
    }

    h5.h5Test {
        margin-left: -23% !important;
    }

    .pTest {
        margin-left: -23% !important;
    }
    .slider-repertoire {
        margin-top: -61% !important;
    }*/

}
@media screen and (min-width: 767px) and (max-width: 991px){
   /* button#btnOwner {
        background-color: #ffffff;
        color: #0c6d93;
        width: 135px;
    }
    button#btnPartner {
        background-color: #ffffff;
        color: #0c6d93;
        width: 135px;
    }
    select#selectNonConnect {
        width: 100% !important;
    }
    p.pRegister {
        width: 85% !important;
        margin-left: 8% !important;
    }
    span.select2-selection.select2-selection--multiple {
        width: 713px !important;
    }
    .h4Test {
         margin-left: 0% !important;
         margin-top: 4% !important;
     }

    h5.h5Test {
        margin-left: 0% !important;
    }

    .pTest {
        margin-left: 0%  !important;
    }
    .slider-repertoire {
        width: 100% !important;
        margin-top: -7% !important;
    }*/
}

@media screen and (max-width: 766px){
    /*button#btnOwner {
        background-color: #ffffff;
        color: #0c6d93;
        width: 127px;
    }
    button#btnPartner {
        background-color: #ffffff;
        color: #0c6d93;
        width: 114px;
    }
    select#selectNonConnect {
        width: 100% !important;
    }
    p.pRegister {
        width: 100% !important;
        margin-left: 0% !important;
    }
    .h4Test {
        margin-left: 0% !important;
        margin-top: 4% !important;
    }

    h5.h5Test {
        margin-left: 0% !important;
    }

    .pTest {
        margin-left: 0%  !important;
    }
    .slider-repertoire {
        width: 100% !important;
        margin-top: -5% !important;
    }*/
}
.FirstBlockRegisterPartner {
    width: 100%;
    background-image: url(../logo/layerRegister.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 25px 0px;
}
.blockConnectionRegister .forms, .blockConnection {
    padding: 35px 0px;
}
.h1Register {
    color: #037096;
    text-transform: uppercase;
    text-align: center;
    font-family: Lato,sans-serif;
}

.register-partner {
    color:  #ffffff;
}

p.pRegister {
    color: #fff;
    margin-left: 21%;
    width: 59%;
    font-size: 15px;
}

.register_intro {
    padding-bottom: 50px;
}

.register_intro p, .register_intro ul li {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 25px;
}

.register_intro h1 {
    color: #0c6d93;
    margin-top: 20px;
    text-transform: uppercase;
}

.register_intro .obtain_blocks {
    margin: 40px 0;
}

.register_intro .obtain_blocks .block {
    margin-bottom: 20px;
    min-height: 90px;
}

.package_array .text {
    margin-bottom: 40px;
}

.package_array h1 {
    color: #0c6d93;
    text-transform: uppercase;
}

.package_array h4 {
    font-size: 16px;
    line-height: 25px;
    margin: 0 auto;
    width: 70%;
}

.package_array .table {
    color: #292929;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
    width: 99%;
}

.package_array .table > thead > tr > th {
    border: 0px;
}


.package_array .table > thead > tr > th:not(:first-child) {
    background-color: #f1f1f1;
    border-right: 1px solid #ddd;
    height: 80px;
    text-align: center;
    vertical-align: center;
}

.package_array .table > thead > tr > th:last-child {
    border-right: 0px;
}


.package_array .table > thead span.price {
    color: #0c6d93;
    font-family: Lato;
    font-size: 22px;
}

.package_array .table > tbody > tr > td {
    padding: 10px 15px;
    white-space: normal;
}

.package_array .table > tbody > tr:first-child > td {
    border-top: 0px!important;
}

.package_array .table > tbody > tr > td:first-child {
    background-color: #f1f1f1;
    width: 37.5%
}

.package_array .table > tbody > tr > td:nth-child(2) {
    border-left: 0px!important;
}

.package_array .table > tbody > tr > td:not(:first-child) {
    border: 1px solid #ddd;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
    width: 12.5%;
}

.blockConnectionRegister {
    width: 100%;
    height: auto;
    background-color: #ffffff;
}
select#selectNonConnect {
    width: 478px;
    font-size: 20px;
    color: #292929;
}
select#selectLeft {
    width: 565px;
    font-size: 20px;
    font-family: Lato,sans-serif;
    margin-left: -3.5%;
}


span.select2-selection.select2-selection--multiple {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #292929;
    border-radius: 0px;
}

p.line_on_side {
    clear:both;
    font-size: 20px;
    margin: 0 auto 15px auto;
    position: relative;
    width: 400px;
}
p.line_on_side span:before,
p.line_on_side span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid #ddd;
  top: 13px;
  width: 200px;
}

p.line_on_side span:before {
    left: -25px;
}

p.line_on_side span:after {
    right: -25px;
}

button#btnConnexion.margin {
    margin: 30px 0 5px 0!important;
}

.btn-offre, .btn-properties, .modal-footer .btn, button#btnConnexion, #contact_form #submitContact, input#btnInscriptionPartner, #btnInscriptionOwner, button#btnInscriptionPartner {
    border: none;
    padding: 10px 0px;
    font-size: 16px !important;
    background-color: #0c6d93 !important;
    font-family: Lato, sans-serif !important;
    text-transform: uppercase;
    font-weight: normal;
    text-shadow: normal !important;
    border-radius: 0px;
    width: 100%;
    border: 0px !important;
    color: #fff !important;
    margin: 0px !important;
}
.modal-footer .btn {
    margin-bottom: 10px !important;
}
button:focus {
  text-decoration: none;
  outline:none;
  border: none;
  box-shadow: none;
}

.changeCaptcha {
    margin-left: 52%;
}
.blockConfirmationRegister {
    width: 100%;
    background-color: #ffffff;
}
.pConfirmation {
    color: #292929;
    font-size: 18px;
    font-family: Lato,sans-serif;
    font-weight: bold;
    text-align: center;
}

input#btnConfirmation {
    width: 355px;
    height: 45px;
    background-color: #0c6d93;
    font-size: 16px;
    font-weight: bolder;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    margin-left:34.5%;
    border-radius: 0px;
    margin-top: 3%;
}

input.searchBlog {
    border: none;
    border-bottom: 1px solid #292929;
    width: 300px;
    float: right;
    margin-top: -4%;
}

#searchBlog::-webkit-input-placeholder {
    color:#292929;
    padding-left: 30px;
    font-family: Lato,sans-serif;
    font-size: 20px;
    font-weight: bolder;
}

.blockArticles {
    width: 100%;
    height: auto;
    background-color: #ffffff;
}
.blockArticles h1 {

}
i#faSearch {
    float: right;
    margin-right: 24%;
    margin-top: -4.2%;
}

p.dateBlog {
    color: #636b6f;
    font-family: Lato,sans-serif;
    font-size: 12px;
}

.h5Blog {
    color: #292929;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    text-transform: none;
}

span.spanBlog {
    color: #636b6f;
    font-weight: normal;
    text-transform: none;
}
span.spanBlog:hover {
    color:#292929 ;
}

.caption.align-left {
    text-align: left;
}

input.btnBlog {
    min-width: 36px;
    width: 100%;
}
.bloglist input.btnBlog {
    max-width: 36px;
}
label.thumbnail {
    padding: 0;
}

.blockTestBlog {
    width:100%;
    height: auto;
    background-color: #ffffff;
}
.h4Test {
    margin-left: -35%;
    color: #037096;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-size: 24px;
    margin-top: -0.2%;
}
h5.h5Test {
    color: #292929;
    text-transform: uppercase;
    font-size: 16px;
    font-family: Lato,sans-serif;
    margin-left: -35%;
}
.pTest {
    font-size: 14px;
    color: #282828;
    margin-left: -35%;
    width: 90%;
    font-family: Lato,sans-serif;
}

p.pLoremIpsumText {
    color: #292929;
    font-size: 14px;
    font-family: Lato,sans-serif;
}
.FirstblockRepertoires {
    width: 100%;
    height: auto;
    background-color: #ffffff;
}
.topRepertoires {
    width: 100%;
    height: 50px;
}
.leftPartenaire {
    width: 100px;
    height: 23px;
    background-color: #0c6d93;
    color: #ffffff;
    float: left;
    font-family: Lato,sans-serif;
    padding-left: 20px;
    text-transform: uppercase;
}
div#leftExtérieur {
    margin-left: 1%;
}
i#croix1 {
    float: left;
    font-size: 12px;
    margin-left: -18%;
    margin-top: 6%;
}
i#croix2 {
    float: left;
    margin-left: -15px;
    margin-top: 6%;
    font-size: 12px;
}

.pServices {
    margin-left: 20%;
    color: #292929;
    font-size: 14px;
    border-left: 1px solid #292929;
    padding-left: 1%;
}
input.inputRepertoires {
    float: left;
    margin-left: 30.5%;
    margin-top: -3%;
    border: none;
    border-bottom: 1px solid #292929;
    width: 39%;
}

i#zoomRepertoires {
    margin-left: 31%;
    margin-top: -38px;
    float: left;
}
input.inputRepertoires::-webkit-input-placeholder { color:#292929;
    font-size: 18px;
    padding-top: -10px;
    padding-left: 7%;
    color:#292929 ;
}
textarea::-webkit-input-placeholder {
    font-size: 14px !important;
    color: #000 !important;
}
.blockRight {
    float: right;
    margin-top: -3%;
    margin-left: 14%;
    font-family: Lato,sans-serif;
    font-size: 15px;
}
p.pBlockRight {
    border-right: 1px solid #292929;
    padding-right: 20px;
    padding-left: 30px;
    float: left;
}
p.pBlockRight:hover {
  color: #0c6d93;
    cursor: pointer;
    text-decoration: line-through;
}
p#alpha {
    border: none;
}
div#fiche-partenaire {
    margin-top: 41%;
}
img#img-fiche {
    z-index: 0;
    top: 25.5%;
}

.h5Repertoires {
    color: #292929;
    font-size: 16px;
    font-family: Lato,sans-serif;
}
h5.h5Recommendations {
    color: #0c6d93;
    font-family: Lato,sans-serif;
}
i#left-chevron {
    margin-left: 46%;
    font-size: 26px;
}
i#left-chevron:hover {
  color: #0c6d93;
}

i#right-chevron {
    font-size: 26px;
    margin-left: 4%;
}

i#right-chevron:hover {
    color: #0c6d93;
}
.SecondBlockRepertoires {
    width: 100%;
    height: auto;
    padding-top: 35px;
    background-color: #fff;
    padding-bottom: 75px;
}
p.p-secondblock {
    margin-left: -2%;
    font-size: 14px;
    color: #292929;
    font-weight: bolder;
    margin-top: 10%;
    width: 92%;
}

.slider-repertoire {
    width: 48%;
    float: right;
    margin-top: -47%;
}

.thirdBlockRepertoires {
    width: 100%;
    height: 90px;
    background-color: #ffffff;
}
.h3fiche-partenaire {
    text-transform: uppercase;
    font-weight: bolder;
    text-align: center;
    color: #fff;
    font-family: Lato,sans-serif;
}
/*.container-conditions {
    padding-left: 40px;
}*/
.select2-container--bootstrap {
    width: 100% !important;
}
/*.input-blog {
    min-width: 57px !important;
    margin-left: -12px;
}*/


@media (max-width:992px) {
    .caption.align-left {
        text-align: center;
    }
}

@media (max-width:768px) {
    .table-responsive {
        border: 0px;
    }

    .package_array .table > tbody > tr > td:first-child {
        min-width: 200px;
    }
}

@media (max-width:768px) {
    .table-responsive {
        border: 0px;
    }

    .package_array .table > tbody > tr > td:first-child {
        min-width: 200px;
    }
}

@media (max-width:635px) {
    .bloglist .col-xs-6 {
        width: 100%;
    }
}


