.left-offres {
    width: 100%;
    height: auto;
    background-color:#f1f1f1 ;
}
.h4-offres {
    color: #037096;
    font-family: Lato,sans-serif;
}
.h5-offres {
    color: #000000;
    font-family: Lato,sans-serif;
    font-size: 16px;
}
.p-offres {
    color: #000000;
    font-size: 14px;
    font-family: Lato,sans-serif;
}
.ownersecondpart form#filters input {
    border: none !important;
    padding: 0px !important;
    min-width: 36px !important;
    width: 100%;
}
p.nom-partenaire {
    text-align: right;
    width: 100%;
    color: #a2a1a1;
    font-family: Lato,sans-serif;
    font-size: 15px;
}
p#date-message {
    text-align: right;
    width: 100%;
    font-size: 11px;
}
button#repondre-offre {
    width:46%;
    height: 80px;
    background-color: #037096;
    font-size: 18px;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    border-radius: 0;
}

button#consulter-fiche {
    width: 46%;
    height: 80px;
    background-color: #292929;
    font-size: 18px;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    border-radius: 0;
}
@media (max-width: 992px) {
    .block-description-tache {
        margin-left: 0 !important;
    }

    .thirdBlockHome .col-md-3, .firstBlockPartner .col-md-3, .ownerthreepart .col-md-3 {
        margin-bottom: 40px;
    }
    .ownerthreepart .h5Blog, .ownerthreepart p.dateBlog, .ownerthreepart .spanBlog span,
    .thirdBlockHome .h5Blog, .thirdBlockHome p.dateBlog, .thirdBlockHome .spanBlog span, .firstBlockPartner .h5Blog, .firstBlockPartner p.dateBlog, .firstBlockPartner .spanBlog span {
        text-align: center;
        float: none;
    }
    ul.list-register {
        margin-left: 30px !important;
    }
    .sousblock-showtask {
        width: 100% !important;
        margin-left: 0px !important;
    }
    a.link-modiftask {
        margin-left: 125px !important;
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    .btn-summaryleft {
        margin-top: 76px;
    }
    ul.list-register {
        margin-left: 50px !important;
    }
}

h2.h2-habitationssolo {
    margin-left: 52%;
    color: #0c6d93;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}

.block-principaltache > .container{
    padding-top: 75px; 
}
.block-principaltache #btn-habitations a, .habitations-block #btn-habitations a, .block-principaltache #btn-habitations a {
    margin-bottom: 20px;
}
.block-principaltache div#thumbail-habitation {
    margin-left: 0px !important;
}
.block-principaltache div#thumbail-habitation {
    margin-bottom: 20px;
}
div#thumbail-habitation a {
    text-decoration: none;
}
.block-habitation {
    height: auto;
    background-color: #f1f1f1;
    padding: 0px 20px;
}
.habitations-block .thumbnail {
    padding: 0px !important;
}
h3.h3-habitationsolo {
    color: #037096;
    font-family: Lato,sans-serif;
}
p.p-habitationsolo {
    color: #000000;
    font-size: 14px;
    font-family: Lato,sans-serif;
    width: 46%;
    font-weight: bolder;
}
.block-taches {
    width: 100%;
    height: auto;
    background-color: #f1f1f1;
    padding: 20px 40px;
}
.decalage-tache .thumbnail {
    padding: 0px;
}
a#thumbail-tache1 {
    background: transparent;
}

a#thumbail-tache2 {
    background: transparent;
}

a#thumbail-tache3 {
    background: transparent;
}
a#thumbail-tache4 {
    background: transparent;
}

a#thumbail-tache5 {
    background: transparent;
}
.block-taches p.nom-taches {
    font-weight: bolder;
    color: #292929;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 10px;
}
.block-taches a.consulter-taches {
    font-size: 16px;
    font-weight: bold;
}
.block-taches .btn-deletetaches {
    font-size: 12px;
    margin-left: 0px;
}
p.consulter-taches {
    color: #0c6d93;
    font-size: 16px;
    font-family: Lato,sans-serif;
    font-weight: bold;
    margin-left: 1%;
}
.taskssingleview {
    padding-bottom: 45px;
}
.taskssingleview p {
    margin: 0px;
}
h3.h2-taches, .taskssingleview h2 {
    color: #0c6d93;
    font-weight: bolder;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
}

.p-add-tache {
    text-align: center;
    color: #0c6d93;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 18px;
    font-family: Lato,sans-serif;
}
.imgtachepart {
    text-align: center;
}
h3.h3-taches {
    float: left;
    color: #0c6d93;
    font-weight: bolder;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 20px;
}
.block-taches .block-planif-tache input {
    width: 100%;
}

.inputnom-tache {
    background: transparent;
    color: #292929;
    width: 100%;
}

.block-description-tache {
    margin-top: 24%;
    margin-left: -110%;
}
button#btn-left {
    margin-left: 9%;
    width: 275px;
    height: 45px;
    background-color: #292929;
    border-color: #292929;
    border-radius: 0;
    font-size: 18px;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
}
button#btn-right {
    font-size: 18px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    border-radius: 0;
    background-color: #0c6d93;
    border-color: #0c6d93;
    width: 275px;
    height: 45px;
    margin-left: 30px;
    font-weight: bolder;
}
p.categories-taches {
    color: #292929;
    font-size: 16px;
    margin-top: 6%;
    text-align: center;
    font-family: Lato,sans-serif;
}
.decalage-categorie {
    margin-left: 12%;
}

.block-fichepartenaire {
    width: 100%;
    height: auto;
    background-color: #ffffff;
}

#thumbnail-fichepartenaire {
    border: 1px solid #f1f1f1;
}

.block-rona {
    width: 100%;
    height:auto;
    background-color: #f1f1f1;
    padding: 10px;
    margin-bottom: 25px;
}
.block-rona input {
    margin-bottom: 25px;
}
.block-rona input.btn {
    background-color: #292929; 
    width: 100%;
    text-align: center;
    border: 0px;
    margin-top: 25px;
    color: #fff;
    text-shadow: none;
    box-shadow: none;
    border-radius: 0px;
} 
.block-rona input.btn:hover {
    background-color: #0c6d93;
}
.h5-fichepartenaire {
    font-size: 16px;
    color: #3d3d3d;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    float: left;
    width: 100%;
}
h5.h5-secondfichepartenaire {
    font-family: Lato,sans-serif;
    font-size: 16px;
    color: #287d9e;
    width: 100%;
}
input.input-nom {
    border: none;
    border-bottom: 1px solid #292929;
    background: transparent;
    width: 100%;
}

input.input-courriel {
    border: none;
    border-bottom: 1px solid #292929;
    background: transparent;
    width: 100%;
}

textarea.textareaMessage {
    border: none;
    border-bottom: 1px solid #292929;
    background: transparent;
    width: 100%;
}
input.input-nom::-webkit-input-placeholder { color:#292929;}
input.input-courriel::-webkit-input-placeholder { color:#292929;}
textarea.textareaMessage::-webkit-input-placeholder { color:#292929;}

button#btn-prix {
    width: 100%;
    background: #037096;
    border-radius: 0;
    color: #fff;
    font-size: 16px;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
}
#block-rightfiche {
    padding-bottom: 35px;
}

h3#h2-categoriename {
    color: #292929;
}
.delete_modal{
    padding: 10px;
    background-color: #0c6d93;
    font-size: 12px;
    border: 0px;
    box-shadow: none;
    border-radius: 0px;
}
.delete_modal:hover {
    background-color: #0c6d93;    
}
.rec_buttons {
    width: 100%;
}
#recommend .recommend-btn, .rec-delete_model {
    padding: 3px 5px !important;
    background-color: #0c6d93;
    border: 0px;
    box-shadow: none;
    border-radius: 0px;
    width: auto !important;
    height: auto !important;
    float: left;
    margin: 0px 5px !important;
}
p.ptop-fiche {
    float: left;
    border-left: 1px solid #3d3d3d;
    font-weight: bolder;
    font-size: 16px;
    text-transform: uppercase;
    width: 100%;
    margin-top: 10px;
}
p#deleteborder-fiche {
    border: none;
}
p#website-fiche {
    text-transform: none;
}

.selection-services {
    width: 100%;
    height: 50px;
    margin-top: 50px;
}
.services {
    float: left;
    width: 120px;
    background-color: #0c6d93;
    margin-right: 30px;
    height: 34px;
}
.services-fa {
    color: #fff;
}
p.p-services {
    color: #fff;
    margin-left: 26%;
    margin-top: -17%;
    font-family: Lato,sans-serif;
}

.block-repondreoffre {
    width: 100%;
    height: 800px;
    background-color: #ffffff;
}
.h2-repondreoffre {
    text-align: center;
    text-transform: uppercase;
    font-weight: bolder;
    color: #0c6d93;
    font-family: Lato,sans-serif;
    margin-top: 10%;
}


h3.h3-nomoffre {
    text-align: center;
    text-transform: uppercase;
    font-weight: bolder;
    color: #292929;
    font-family: Lato,sans-serif;
}
input.titre-message {
    border: none;
    border-bottom: 1px solid #292929;
}


textarea.description-message {
    border: none;
    border-bottom: 1px solid #292929;
    margin-top: 30px;
}

.block-repondreoffre .btn-offre {
    background-color: #037096;
    margin-top: 25px !important;
    border-radius: 0;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    font-size: 20px;
    text-transform: uppercase;
}

p#website-fiche:hover {
    color: #037096;
}
.summary {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    padding-bottom: 50px;
}
.h2-summary {
    text-align: center;
    color: #037096;
    font-family: Lato, sans-serif;
    text-transform: uppercase;
    font-weight: bolder;
}
.h3-summary {
    text-align: center;
    color: #292929;
    text-transform: uppercase;
    font-family: Lato, sans-serif;
}
.h4-summary {
    color: #037096;
    font-family: Lato, sans-serif;
}

input.input-summary::-webkit-input-placeholder {
    color:#292929;
    font-size: 18px;
}
.btn-summaryleft {
    width: 311px;
    height: 45px;
    background-color: #292929;
    border-radius: 0;
    font-size: 18px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    margin-left: 110px;
}

.btn-summaryright {
    width: 311px;
    border-radius: 0;
    background-color: #292929;
    height: 45px;
    font-size: 18px;
    font-weight: bolder;
    text-transform: uppercase;
    margin-left: 110px;
    font-family: Lato, sans-serif;
    margin-top: 10px;
}
.service-un {
    background: #f1f1f1;
    float: left;
    width: 156px;
    height: 35px;
    color: #292929;
    font-weight: bolder;
    font-family: Lato, sans-serif;
}

.service-deux {
    background: #f1f1f1;
    float: left;
    width: 156px;
    height: 35px;
    margin-left: 10px;
    color: #292929;
    font-weight: bolder;
    font-family: Lato, sans-serif;
}

.service-trois {
    background: #f1f1f1;
    float: left;
    width: 156px;
    height: 35px;
    color: #292929;
    font-weight: bolder;
    font-family: Lato, sans-serif;
}

.service-quatre {
    background: #f1f1f1;
    float: left;
    width: 156px;
    height: 35px;
    margin-left: 10px;
    color: #292929;
    font-weight: bolder;
    font-family: Lato, sans-serif;
}
.fa-addhabitation {
    font-size: 15px;
    margin-top: 8px !important;
}


.btn-close {
    max-width: 370px;
    width: 100%;
    height: 45px;
    background-color: #037096;
    border-radius: 0;
    font-size: 18px;
    font-weight: bolder;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
}

.btn-continuer {
    max-width: 370px;
    width: 100%;
    height: 45px;
    border-radius: 0;
    background-color: #037096;
    margin-top: 16px;
    font-size: 18px;
    font-weight: bolder;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
}
.habitations-block {
    margin-top: 0;
    padding-bottom: 61px;
    padding-top: 30px;
}
.habitations-block .btn-delete-habitations {
    width: auto !important;
    font-size: 12px !important;
    margin: 0px !important;
    height: auto !important;
    padding: 5px 10px !important;

}
.habitations-block a.thumbnail img {
    border: solid 1px #f1f1f1;
}
a.btn.btn-info.active.btn-habitations, .block-principaltache #btn-habitations a {
    padding: 20px;
    display: block;
    background-color: #292929;
    font-size: 20px;
    font-family: Lato;
    text-transform: uppercase;
    border-radius: 0;
    border: none;
    width: 100% !important;
    height: auto !important;
}
#btn-habitations > .row >a:hover, #btn-habitations > .row >a:focus {
    color: #fff !important;
}
a.btn.btn-info.active.btn-habitations:hover, a.btn.btn-info.active.btn-habitations:focus {
    background-color: #0c6d93;
}
.ajout-habitations {
    margin-top: 200px;
}
.btn-habitations-delete {
    width: 150px;
    height: 35px;
    border-radius: 0;
    background-color: #0c6d93;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bolder;
    font-family: Lato,sans-serif;
}
.label-ajouthabitations {
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    color: #0c6d93;
}
.edithabitation-labels {
    color: #0c6d93;
}
.btn-taches {
    padding: 20px;
    display: block;
    width: auto !important;
    height: auto !important; 
    background-color: #292929 !important;
    font-size: 17px !important;
    font-family: Lato,sans-serif;
}
.btn-taches:hover {
    background-color: #037096 !important;
    color: #fff !important;
}
.block-principaltache {
    margin-top: 0;
}
.btn-deletetaches {
    width: 100px;
    border-radius: 0;
    background-color: #037096;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    padding-left: 10px;
    margin-left: 5px;
}

.btn-modifiertask {
    width: 100px;
    margin-top: 10px;
    border: none;
    background-color: #037096;
    height: 36px;
    margin-left: 5px;
}
.link-task {
    color: #fff;
    text-transform: uppercase;
     font-weight: bolder;
    font-family: Lato,sans-serif;
}
a.link-task:hover {
    text-decoration: none;
}
.block-images {
    margin-top: 0;
}
ul.select2-selection__rendered {
    background-color: #f1f1f1;
}
.btn-confirm-taches {
    height: 45px;
    border-radius: 0;
    background-color: #0c6d93;
    font-size: 18px;
    font-family: Lato, sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
}
.btn-paint-color {
    width: 400px !important;
    height: 80px !important;
    padding-top: 22px;
    background-color: #292929 !important;
    font-size: 17px !important;
    font-family: Lato,sans-serif;
}
.btn-paint-color:hover {
    background-color: #0c6d93 !important;
    color: #fff !important;
}
.create-paintcolors {
    width: 100%;
    height: auto;
    background: #fff;
    padding-bottom: 180px;
}
.label-create-paintcolors {
    color: #0c6d93;
    font-size: 18px;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
}
.text-paintcolors {
    border: none;
    border-bottom: 1px solid #292929;
}
.btn.btn-paintcolor {
    width: 100%;
}
.btn-paintcolor {
    border-radius: 0;
    background-color: #0c6d93;
    font-size: 18px;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    margin-top: 30px;
}
.add-margin {
    padding-top: 100px;
}

.btn-none {
    position: absolute;
    border: none;
    background: transparent;
}
.add-padding {
    padding-bottom: 40px;
}
.add-decalage-profil {
    margin-top: 2px;
    padding: 60px 80px;
}
.blockProfilowner {
    width: 100%;
    height: auto;
    background-color: #ffffff;
}
.thumbnailRepertoire {
    display: inline-table;
}
.content-images {
    height: 200px;
}
.check-images {
    opacity:0.5;
    color: #996;
}

.btn-modal-confirm {
    background: #0c6d93;
    width: 300px;
    margin-right: 0px;
    margin-top: 30px;
    height: 45px;
    border-radius: 0px;
    color: #fff;
    font-size: 18px;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    border: none;
    float: left;
    margin-left: 121px;
}
.btn-modal-confirm:hover {
    background: #0c6d93;
    color: #ffffff;
}
.btn-modal-close {
    background: #0c6d93;
    width: 300px;
    margin-top: 30px;
    height: 45px;
    border-radius: 0px;
    color: #fff;
    font-size: 18px;
    font-family: Lato, sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    border: none;
    float: left;
}
.btn-modal-close:hover {
    background: #0c6d93;
    color: #ffffff;
}
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
.btn-file-img {
    width: 300px;
    height: 45px;
    padding-top: 7px;
    font-size: 30px;
    margin-left: 99px;
    background: #0c6d93;
    border-radius: 0px;
}
.submit-modal {
    width: 188px;
    height: 45px;
    background: #0c6d93;
    color: #fff;
    text-transform: uppercase;
    margin-left: 90px;
    border-radius: 0px;
    font-family: Lato,sans-serif;
    font-weight: bolder;
}
.submit-modal:hover {
    background-color: #0c6d93;
    color: #ffffff;
}
.input-name-img {
    margin-left: 30px;
    box-shadow: none;
    border: 1px solid #292929;
    border-radius: 0px;
    margin-top: 0px;
    height: 45px;
}
.btn-delete-habitations {
    margin-left: -15px;
    border-radius: 0px;
}
ul.list-register {
    color: #fff;
    margin-left: 215px;
}

p.options-modals {
    margin-top: -23px;
    margin-left: 34%;
    color: #0c6d93;
    font-size: 16px;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
}

input[type="checkbox"] {
    margin-left: 12%;
}

.form-checkbox input[type="checkbox"] {
    margin-left: 0;
}
.augmentation-taille {
    width: 235px;
}
p.cat-owner {
    color: #0c6d93;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    font-size: 14px;
}

.block-showtask {
    width: 100%;
    background: #fff;
}
.span-created {
    color: #0c6d93;
    font-size: 14px;
    font-weight: bolder;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
}
.sousblock-showtask {
    width: 40%;
    height: auto;
    background: #f1f1f1;
    margin-left: 350px;
    margin-top: 30px;
    padding-bottom: 30px;
}
button.btn-modification {
    margin-left: 45px;
    margin-top: 50px;
    width: 48%;
    border: none;
    background: #0c6d93;
    height: 45px;
    margin-bottom: 51px;
}
a.link-modiftask {
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    background: #0c6d93;
    padding: 10px;
    margin-left: 200px;
}
a.link-modiftask:hover {
    color: #fff !important;
}
p.decal-task {
    margin-left: 125px;
}
.decal-icons {
    margin-left: 125px;
}

.block-edittask {
    width: 100%;
    height: auto;
    background: #fff;
}
.sousblock-edittask {
    width: 100%;
    height: auto;
    background: #f1f1f1;
    margin-left: 300px;
    margin-bottom: 100px;
    padding: 20px;
}
.bg-edittask {
    background-color: #f1f1f1;
    width:100px;
    height: 64px;
}

input.btn-saveedittask {
    margin-left: 0;
    margin-top: 50px;
    width: 100%;
    border: none;
    background: #0c6d93;
    height: 45px;
    color: #fff;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    font-size: 16px;
    TEXT-TRANSFORM: uppercase;
}

img.testimg {
    margin-top: 50px;
}

.home-grey-row .btn.btn-info, .home-grey-row .col-md-6 div {
    margin-bottom: 10px;
}

.cat-icons {
    width: 36px;
    height: 36px;
    float: left;
    margin: 2px;
}