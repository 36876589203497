html, body {height: 100%;}

#footer .container {
    width: 1100px !important;
}

footer {
    background-color: #292929;
}

footer .logo {
    margin-bottom: 20px;
}

footer .text {
    color: #ccd5d9;
    font-size: 14px;
    line-height: 22px;
}

footer h4 {
    color: #ffffff;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

footer ul {
    list-style: none;
    margin-left: 0;
    padding-left: 16px;
}

footer ul li {
    color: #ccd5d9;
    margin: 5px 0;
}

footer .top_footer ul > li:before {
    display: inline-block;
    content: "-";
    width: 1em;
    margin-left: -1em;
}

footer ul li a {
    color: #ccd5d9;
    font-family: Lato;
    text-decoration: underline!important;
}

footer a:not(.btn-social):hover {
    color: #ffffff!important;
}

nav#footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #292929 !important;
    background-image: none;
    height: 173px;
}
.logo-footer {
    position: relative;
}

.copyright {
    padding: 0 0 35px 0;
}

.copyright p {
    color: #909699;
    float: left;
    font-family: Lato,sans-serif;
    font-size: 12px;
}

.copyright a {
    color: #909699;
    text-decoration: underline!important;
}

.copyright .container .content {
    border-top: 1px solid #3f3f3f;
    padding-top: 15px;
}

.ulFooter {
    float: left;
    padding-right: 20px;
}
li.liFooter {
    list-style: none;
}
li.liFooter > a {
    font-family: Lato, serif;
    font-weight: lighter;
    color: #ffffff;
    text-decoration: none;
}
li.liFooter > a:hover {
    color: #ffffff !important;
}
.social-network {
    float: right;
    margin-top: -7px;
}
a.btn-social.btn-outline {
    color: #fff;
    font-size: 15px;
}

li.social-networkLi {
    display: inline-block;
}
* {
    margin: 0;
}
html, body {
    height: 100%;
}
.wrapper {
    min-height: 100%;
    height: 100%;
}
.top_footer {  
    padding: 70px 0 35px 0;
}
.top_footer .navbar-brand {
    height: auto;
    padding-left: 0px;
}
.footerwidget {
    float: right;
    padding-top: 14px;   
}

@media (max-width:992px) {

    .footer h4 {
        text-align: left;
    }

    .footerwidget {
        float: left;
    }

    .footerwidget h4 {
        margin-top: 30px;
    }
}    
