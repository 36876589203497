.home {
    overflow-x: hidden;
}

.home h2 {
    color: #292929;
    font-family: Lato,sans-serif;
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 1px;
    margin-bottom: 25px;
}

.home h3 {
    color: #292929;
    font-family: Lato,sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 25px;
}

.home h4 {
    font-size: 16px;
    line-height: 22px;
}

.home p {
    font-family: Lato,sans-serif;
}

.home .btn.btn-info {
    border-radius: 0px;
    white-space: normal;
}

.home .btn.btn-info:not(.small) {
    border: 0px;
    box-shadow: none;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: bold;
    height: 100%;
    letter-spacing: 2px;
    line-height: 40px;
    margin-top: 25px;
    text-transform: uppercase;
    width: 360px;
}

.home .btn.btn-info.transparent:not(.small) {
    background-color: transparent;
    border: 1px solid #0c6d93;
    color: #0c6d93;
    width: 310px;
}

.home .btn.btn-info.light {
    background-color: #ffffff;
    color: #0c6d93;
}

.home .btn.btn-info:hover {
    background-color: #ffffff;
    color: #0c6d93!important;
}

/* nav */
.content_section {
    background-color: #ffffff;
}

.home .topbarPartner .contient {
    border-bottom: 1px solid #485d66;
    margin-bottom: 20px;
}

.topbarPartner .contient .logo {
    background-color: transparent;
}

.topbarPartner nav#homeColor {
    background-color: transparent;
}

.topbarPartner .navbar {
    box-shadow: none;
    margin-top: 5px;
}

.background {
    background: url("../logo/home_background.jpg") no-repeat top center, linear-gradient(to right, rgba(35,55,62,1) 0%, rgba(35,55,62,1) 51%, rgba(54,73,81,1) 55%, rgba(54,73,81,1) 100%);
    background-size: cover;
    height: 800px;
    position: absolute;
    top: 0px;
    width: 100%;
}

/* intro */
.intro {
    margin-bottom: 250px;
    margin-top: -175px;
    /*
    background: url(../logo/home_background.jpg) top center / cover no-repeat fixed;
    height: 800px;
    margin-top: -140px;
    width: 100%*/
}

.intro .container {
    margin-top: 230px;
}

.intro .container .content_right {
    margin-left: 15px;
}

.intro .container h1 {
    color: #ffffff;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
    width: 95%;
}

.intro .container ul {
    list-style-type: none;
    padding-left: 0px;
}

.intro .container ul li {
    background-image: url('../logo/ico_check_light.png');
    background-repeat: no-repeat;
    color: #ffffff;
    font-family: Lato,sans-serif;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 30px;
    margin-bottom: 10px;
    padding-left: 50px;
}

.intro .container ul li:last-of-type:after {
    background-image: url('../logo/ico_device_small.png');
    content: '';
    display: inline-block;
    margin-left: 20px;
    height: 20px;
    width: 100px;
}

.intro .container .image {
    position: relative;
}

.intro .container .image img {
    margin-top: 30px;
    position: absolute;
    top: 0px
}

.intro .container .btn.transparent {
    border-color: white!important;
    color: white!important;
}

.intro .container .btn.transparent:hover {
    background-color: #ffffff;
    color: #0c6d93!important;
}

/* fonction */

.fonction {
    margin-top: 20px;
    padding-bottom: 80px;
}

.fonction .content {
    margin-bottom: 40px;
}

.fonction .content h4 {
    margin: 10px auto;
    width: 60%;
}

.fonction .blocks div {
    margin-bottom: 20px;
}

/* partner */
.partner {
    background-color: #f1f1f1;
    padding: 100px 0;
}

.partner a.btn.btn-info {
    border: 1px solid #538db0!important;
    margin: 0 5px;
}

.partner a.btn.btn-info:hover {
    background-color: #292929!important;
    border: 1px solid #292929!important;
    color: #ffffff!important;
}

/* logo */

/* benefice */
.benefice {
    background: /*url("../logo/background_ipad.png") no-repeat center right, */linear-gradient(to left, #84979f, #23373e);   
    padding: 100px 0;
    position: relative;
}

.benefice a.underline:hover {
    color: #ffffff!important;
    cursor: pointer;
}

.benefice .background_img {
    position: absolute;
    right: 0;
    top: 30%;
}

.benefice h2, .benefice h3, .benefice a.underline {
    color: #ffffff;
}

.benefice h4, .benefice p {
    color: #ccd5d9;
}

.benefice .row {
    margin: 20px 0;
}

.benefice .block.pr { 
    padding-right: 40px;
}

.benefice .p0 {
    padding: 0;
}

/* testimony */
.testimony {
    padding: 100px 0;
}

.testimony .row {
    margin: 20px 0;
}

.testimony .info {
    color: #292929;
    text-align: right;
}

.testimony .info h3 {
    margin: 0 0 5px 0;
}

.testimony .text {
    color: #636b6f;
    font-size: 16px;
    font-style: italic;
    line-height: 25px;
}

/* tip */
.tip {
    padding: 100px 0;
}

.tip .content {
    margin-bottom: 50px;
}

/* signup */
.signup {
    background-image: url('../logo/background_house.jpg');
    background-size: cover;
    color: #ffffff;
    height: 416px;
    padding-top: 100px;
}

.signup h2 {
    color: #ffffff;
}

.signup img {
    margin-top: 30px;
}

.signup .btn.btn-info.light {
    float: right;
    margin-top: 80px;
}

.signup a.btn.btn-info.light:hover {
    background-color: #292929!important;
    color: #ffffff!important;
}

@media (max-width:1200px) {
    .intro {
        margin-bottom: 170px;
    }
    .intro .container .image img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .benefice .background_img {
        width: 55%;
    }

    .signup {
        padding: 50px 0;
    }
}

@media (max-width:992px) {
    .home {
        text-align: center!important;
    }
    .home ul li {
        text-align: left!important;
    }

    img {
        margin: 0 auto;
    }

    .btn:not(.nav) {
        width: 100%!important;
    }

    .home .topbarPartner .contient {
        padding: 25px 0;
    }

    .intro {
        margin-top: -260px;
    }

    .intro .container .content_right {
        margin-left: 0px;
    }

    .intro .container h1 {
        text-align: left;
    }

    .benefice {
        padding: 30px 0;
    }

    .benefice .block.pr { 
        padding-right: 15px;
    }

    .testimony {
        padding: 30px 0;
    }

    .testimony .info {
        text-align: center;
    }

    .tip {
        padding: 30px 0;
    }

    .signup .btn.btn-info.light {
        margin-top: 30px;
    }
}

@media (max-width:768px) {
    .btn.nav {
        width: 100%!important;
    }

    .background {
        background: #304852;
        height: 500px;
    }

    .intro {
        background: #304852;
        margin-bottom: 0px;
        padding-bottom: 30px;
    }

    .intro .container h1 {
        font-size: 40px;
        line-height: 50px;
        text-align: center;
    }

    .signup{
        background: #1c6687;
        height: 100%;
    }

    .signup h2 {
        margin-top: 0px;
    }

    .home .topbarPartner .contient {
        border-bottom: 0px;
    }
    .navbar-default .navbar-collapse {
        border-top: 0px;
        box-shadow: none;
    }
}


